<template>
    <Modal ref="resetPasswordModal" v-model="showResetPasswordModal" :title="$t('authentication.reset-password.title')" size="lg">
        <validation-observer v-slot="{ invalid }" slim>  
            <form role="form" @submit="onFormSubmit" name="reset-password">
                <TextField 
                    v-bind:value.sync="form.email" 
                    id="email" 
                    fieldType="email"
                    :label="$t('authentication.login.form.email')" 
                    :placeholder="$t('authentication.login.form.email_placeholder')" 
                    :inputClass="{'form-control':true, 'sm-form-control':true, 'required':true}"
                    validationMode="aggressive"
                    :required="true"
                />
                <button :disabled="invalid" class="button button-3d m-0 ladda-button reset-password" data-style="zoom-in" type="submit" value="submit">{{ $t('authentication.reset-password.button') }}</button>
            </form>
        </validation-observer>
    </Modal>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import Modal from '@fwk-client/components/panels/bootstrap/Modal.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { types as layoutTypes } from '@root/src/client/store/layout';

@Component({
  components: { 
    Modal,
    TextField
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class ResetPasswordModal extends Vue {

    get showResetPasswordModal() {
        return this.$store.getters['layout/'+layoutTypes.getters.GET_SHOW_RESET_PASSWORD_MODAL];
    }
    set showResetPasswordModal(show:boolean) {
        this.$store.commit('layout/'+layoutTypes.mutations.SET_SHOW_RESET_PASSWORD_MODAL, show);
    }

    @Watch('showContactForm')
    showContactModal(val:boolean) {
        if(val == true && this.$gtag) {
            this.$gtag.event('Show', {
                'event_category': 'Reset password modal'
            })
        }
    }

    form = {
        email: ''
    };

    laddaSubmit:Ladda.LaddaButton|null = null;

    created() {
        
    }

    mounted() {
        
    }

    onFormSubmit(evt:Event) {
        // We prevent submit of the page
        evt.preventDefault();

        if(this.laddaSubmit) { this.laddaSubmit.remove(); }
        var resetPasswordButton:HTMLButtonElement|null = document.querySelector( 'form[name=reset-password] button.ladda-button.reset-password' );
        this.laddaSubmit = Ladda.create(resetPasswordButton!);

        var input = {
            "email" : this.form.email
        }

        var options:api.ApiVueOptions =  {
            app: this
        }

        this.laddaSubmit!.start();
        
        api.postAPI('/api/auth/reset-password', input, options).then((response:any) => {
            if(response.reseted) {
                // We emit event as password is reseted
                this.$emit('password-reseted');
                this.showResetPasswordModal = false;
            }
            this.form.email = '';
            this.laddaSubmit!.stop();
        });
    }
    
}
</script>